<template>
	<div>
		<home-iphone v-if="isMobile"></home-iphone>
		<home-pc v-if="!isMobile"></home-pc>
	</div>
</template>  

<script>
import HomeIphone from './Home-iphone.vue'
import HomePc from './Home-pc.vue'
export default {
    name: "Home",
	components:{
		HomeIphone,
		HomePc,
	},
    data() {
        return {
			isMobile:null,
			one:true
        }
    },
    created() { },
    mounted() {
		this.isMobile = this.$isMobile
	},
    methods: {
		gowheel(event){
			if (event.deltaY > 0 && this.one==true) {
				this.$refs.swiper.next();
				this.one=false;
				setTimeout(()=>{
					this.one=true
				},1100)
			}
			
			if (event.deltaY < 0 && this.one==true) {
				this.$refs.swiper.prev();
				this.one=false;
				setTimeout(()=>{
					this.one=true
				},1100)
			}
    	},
    }
}
</script>